.g-recaptcha {
  margin-top: 24px;
}

.modal-form.show {
  display: block;
}

.modal-form {
  z-index: 2000;
  background-color: #0003;
  width: 100%;
  height: 100%;
  padding: 8px;
  font-size: 16px;
  line-height: 20px;
  animation-name: backdrop;
  animation-duration: .4s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@keyframes backdrop {
  from {
    background-color: #0000;
  }

  to {
    background-color: #0003;
  }
}

.modal-close-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal-close-icon:after, .modal-close-icon:before {
  content: "";
  border-left: 2px solid #d6d6d6;
  height: 30px;
  transition: border .2s;
  position: absolute;
  left: 25px;
}

.modal-close-icon:after {
  transform: rotate(45deg);
}

.modal-close-icon:before {
  transform: rotate(-45deg);
}

.modal-close-icon:hover:before, .modal-close-icon:hover:after {
  border-color: #aaa !important;
}

.modal-container {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  max-width: 480px;
  margin: 24px auto;
  padding: 40px;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
}

.modal-container .main-form {
  gap: 0;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -100px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.modal-container small {
  font-size: .75em;
  line-height: 1.3;
  display: inline-block;
}

.modal-container small > a {
  line-height: 1.3;
  display: inline-block;
}

.callbackForm.modal-form {
  overflow: inherit;
  background-color: #0000;
  width: auto;
  height: auto;
  padding: 0 0 80px 80px;
  animation: none;
  top: auto;
  bottom: 0;
  left: 0;
}

.callbackForm .modal-container {
  opacity: 0;
  max-width: 500px;
  padding-left: 72px;
  box-shadow: 0 8px 40px #00000029;
}

.callbackForm .modal-container > svg {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-45%, -22%);
}

.callbackForm .modal-container small {
  color: #00000073;
}

.main-form {
  position: relative;
}

.form-header {
  margin-top: -12px;
}

.form-header > h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.form-header > h4 {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.form-header + .form-container, .form-container + .form-footer, .form-header + .form-footer {
  border-top: 1px solid #eee;
  margin-top: 24px;
  padding-top: 24px;
}

.form-footer > .form-field + .form-field, .form-container > .form-field + .form-field {
  margin-top: 12px;
  margin-bottom: 0;
}

@media (max-width: 640px) {
  .form-field.form-field--personal-data label {
    flex-flow: row;
  }
}

.form-field--not-accent {
  color: #888;
}

.form-field label {
  flex-wrap: wrap;
  gap: 4px;
  display: flex;
}

.form-field .text-wrapper {
  margin-bottom: 2px;
}

.form-field input[type="text"], .form-field input[type="tel"], .form-field input[type="email"] {
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
  flex: 100%;
  width: auto;
  min-width: 0;
  padding: 16px 12px;
  font-size: 16px;
  line-height: 20px;
  transition: box-shadow, border .2s;
  box-shadow: 0 0 #0000;
}

.form-field input[type="text"]:hover, .form-field input[type="tel"]:hover, .form-field input[type="email"]:hover {
  border-color: #333;
}

.form-field input[type="text"]:active, .form-field input[type="tel"]:active, .form-field input[type="email"]:active, .form-field input[type="text"]:focus, .form-field input[type="tel"]:focus, .form-field input[type="email"]:focus {
  border-color: #41054f;
  box-shadow: 0 0 0 2px #41054f;
}

.primary-button, .form-field input[type="submit"] {
  color: #fff;
  -webkit-appearance: none;
  background-color: #15a429;
  border: 0;
  border-radius: 8px;
  outline: none;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  transition: background, box-shadow .2s;
  box-shadow: 0 0 #0000;
}

.primary-button:hover, .primary-button:focus, .form-field input[type="submit"]:hover, .form-field input[type="submit"]:focus {
  background-color: #2eb63e;
}

.primary-button:focus, .form-field input[type="submit"]:focus {
  box-shadow: 0 0 0 3px #15a22733;
}

.primary-button:active, .form-field input[type="submit"]:active {
  background-color: #15a429;
}

.form-field--with-action {
  align-items: center;
  gap: 12px;
  display: flex;
}

@media (max-width: 640px) {
  .form-field--with-action {
    flex-flow: column;
    align-items: stretch;
  }
}

.form-field--with-action .action {
  margin-left: 10px;
}

.errorsContainer {
  color: #d80808;
  background: #d808081a;
  border: 1px solid #d8080866;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;
}

.openCallBackModal {
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  width: 84px;
  height: 84px;
  position: absolute;
  bottom: 64px;
  left: 35px;
  transform: translateY(0);
}

.openCallBackModal svg {
  z-index: 10;
  position: relative;
}

.openCallBackModal:before {
  background: linear-gradient(#8a00aa 0%, #5c0670 100%);
  border-radius: 50%;
  width: 84px;
  height: 84px;
  box-shadow: 10px 14px 24px #00000040;
}

.openCallBackModal:after {
  opacity: .12;
  background: linear-gradient(#8a00aa 0%, #5c0670 100%);
  width: 112px;
  height: 112px;
}

.openCallBackModal:after, .openCallBackModal:before {
  content: "";
  border-radius: 50%;
  transition: all .2s;
  animation: 2s ease-in 1s infinite alternate pulse;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

@keyframes pulse {
  from {
    transform: translate(-50%, -50%)scale(1);
  }

  to {
    transform: translate(-50%, -50%)scale(1.15);
  }
}

.openCallBackModal:hover:before {
  animation: none;
  transform: translate(-50%, -50%)scale(1.05);
}

.openCallBackModal:hover:after {
  animation: none;
  transform: translate(-50%, -50%)scale(1.2);
}

.openCallBackModal:active:after, .openCallBackModal:active:before {
  transform: translate(-50%, -50%)scale(.95);
}

.callbackForm.modal-form {
  z-index: 2000;
  width: auto;
  height: auto;
  display: block;
  top: auto;
  bottom: 0;
  left: 0;
  overflow: visible;
}

.callbackForm .modal-container {
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  animation: none;
  position: fixed;
  bottom: 90px;
  left: 70px;
  transform: translateX(-60px);
}

@media (max-width: 640px) {
  .callbackForm .modal-container {
    z-index: 100;
    border-radius: 24px;
    width: 100%;
    padding: 56px 32px 64px;
    bottom: 0;
    left: 0;
  }
}

.callbackForm.active .modal-container {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.giftForm .button-gift.hide {
  visibility: hidden;
  opacity: 0;
}

.giftForm.active .modal-container {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.giftForm.modal-form {
  overflow: inherit;
  background-color: #0000;
  width: auto;
  height: auto;
  padding: 0 0 40px;
  animation: none;
  inset: auto 0 0 auto;
}

.giftForm .modal-container {
  opacity: 0;
  background: linear-gradient(122deg, #ffd4d7 7.8%, #e4d4ff 58.31%, #d4ddff 89.29%);
  border-color: #e4d4ff;
  max-width: 500px;
  box-shadow: 0 8px 40px #00000029;
}

.giftForm .modal-container .modal-close-icon:after, .giftForm .modal-container .modal-close-icon:before {
  border-left: 2px solid #00000052;
}

.giftForm .modal-container .modal-close-icon:hover:before, .giftForm .modal-container .modal-close-icon:hover:after {
  border-color: #0009 !important;
}

.giftForm .modal-container .form-header {
  flex-direction: column;
  gap: .5em;
  display: flex;
}

.giftForm .modal-container .form-header > h4 {
  margin-bottom: 0;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.3em;
}

.giftForm .modal-container .form-header > p {
  opacity: .9;
  margin: 0;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.5em;
}

.giftForm .modal-container > svg {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-45%, -22%);
}

.giftForm .modal-container small {
  color: #00000073;
}

.giftForm.modal-form {
  z-index: 1900;
  width: auto;
  height: auto;
  display: block;
  inset: auto 0 0 auto;
  overflow: visible;
}

.giftForm.modal-form.active {
  z-index: 2100;
}

.giftForm .modal-container {
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  animation: none;
  position: fixed;
  bottom: 90px;
  right: 70px;
  transform: translateX(-60px);
}

@media (max-width: 640px) {
  .giftForm .modal-container {
    z-index: 100;
    border-radius: 24px;
    width: 100%;
    padding: 56px 32px 64px;
    bottom: 0;
    left: 0;
  }
}

.giftForm .button-gift {
  cursor: pointer;
  background-color: #0000;
  background-image: url("images/gift/gift.b7767de7.png");
  background-repeat: no-repeat;
  background-size: 132px;
  border: 0;
  width: 132px;
  height: 132px;
  transition: all .2s;
  animation: 10s infinite b-stay;
  display: block;
  transform: scale(1);
  outline: none !important;
}

.giftForm .button-gift label {
  background-color: var(--color-orangered-100);
  color: var(--color-white);
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 6px;
  font-size: 12px;
  display: inline-block;
  box-shadow: 0 2px 4px #00448c3d;
}

.giftForm .button-gift:hover {
  animation: 8s infinite b-stay, .3s forwards b-hover;
}

.giftForm.active .button-gift {
  animation: 8s infinite b-stay, .3s forwards b-open;
}

@keyframes b-open {
  100% {
    transform: scale(.92);
  }
}

@keyframes b-hover {
  100% {
    transform: scale(1.08);
  }
}

@keyframes b-stay {
  20% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.05);
  }
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

/*# sourceMappingURL=index.2f0b30b7.css.map */
