.g-recaptcha {
  margin-top: 24px;
}

.modal-form,
.modal-form > * {
}

.modal-form.show {
  display: block;
}

.modal-form {
  display: none;
  z-index: 2000;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 8px;
  font-size: 16px;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-animation-name: backdrop;
  -webkit-animation-duration: 0.4s;
  animation-name: backdrop;
  animation-duration: 0.4s;
}

@-webkit-keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.modal-close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  height: 40px;
}
.modal-close-icon:after,
.modal-close-icon:before {
  content: "";
  position: absolute;
  height: 30px;
  border-left: 2px solid #d6d6d6;
  left: 25px;
  transition: border 0.2s;
}
.modal-close-icon:after {
  transform: rotate(45deg);
}
.modal-close-icon:before {
  transform: rotate(-45deg);
}
.modal-close-icon:hover::before,
.modal-close-icon:hover::after {
  border-color: #aaa !important;
}

.modal-container {
  position: relative;
  max-width: 480px;
  margin: 24px auto;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: #fff;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-container .main-form {
  gap: 0;
}

@-webkit-keyframes animatetop {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.modal-container small {
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.3;
}

.modal-container small > a {
  line-height: 1.3;
  display: inline-block;
}

.callbackForm.modal-form {
  left: 0;
  top: auto;
  bottom: 0;
  width: auto;
  height: auto;
  background-color: transparent;
  padding: 0;
  padding-left: 80px;
  padding-bottom: 80px;
  overflow: inherit;
  animation: none;
}

.callbackForm .modal-container {
  opacity: 0;
  max-width: 500px;
  padding-left: 72px;
  box-shadow: 0 8px 40px rgb(0, 0, 0, 0.16);
}

.callbackForm .modal-container > svg {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-45%, -22%);
}

.callbackForm .modal-container small {
  color: rgba(0, 0, 0, 0.45);
}

.main-form {
  position: relative;
}

.form-header {
  margin-top: -12px;
}

.form-header > h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 12px;
}
.form-header > h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 4px;
}

.form-container {
}

.form-header + .form-container {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #eee;
}

.form-container + .form-footer,
.form-header + .form-footer {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #eee;
}

.form-footer > .form-field + .form-field,
.form-container > .form-field + .form-field {
  margin-top: 12px;
  margin-bottom: 0;
}

.form-field--personal-data {
  /* color: #888; */
}

@media (max-width: 640px) {
  .form-field.form-field--personal-data label {
    flex-flow: row nowrap;
  }
}

.form-field--not-accent {
  color: #888;
}

.form-field label {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.form-field .text-wrapper {
  margin-bottom: 2px;
}

.form-field input[type="text"],
.form-field input[type="tel"],
.form-field input[type="email"] {
  padding: 16px 12px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #aaa;
  width: auto;
  min-width: 0;
  flex: 1 1 100%;
  box-shadow: 0 0 0 0 transparent;
  outline: none;
  transition: box-shadow, border 0.2s;
}

.form-field input[type="text"]:hover,
.form-field input[type="tel"]:hover,
.form-field input[type="email"]:hover {
  border-color: #333;
}

.form-field input[type="text"]:active,
.form-field input[type="tel"]:active,
.form-field input[type="email"]:active,
.form-field input[type="text"]:focus,
.form-field input[type="tel"]:focus,
.form-field input[type="email"]:focus {
  box-shadow: 0 0 0 2px #41054f;
  border-color: #41054f;
}

.primary-button,
.form-field input[type="submit"] {
  background-color: #15a429;
  border: 0;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  color: #fff;
  box-shadow: 0 0 0 0 transparent;
  transition: background, box-shadow 0.2s;
  outline: none;
  -webkit-appearance: none;
}
.primary-button:hover,
.primary-button:focus,
.form-field input[type="submit"]:hover,
.form-field input[type="submit"]:focus {
  background-color: #2eb63e;
}
.primary-button:focus,
.form-field input[type="submit"]:focus {
  box-shadow: 0 0 0 3px rgba(21, 162, 39, 0.2);
}
.primary-button:active,
.form-field input[type="submit"]:active {
  background-color: #15a429;
}

.form-field--with-action {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media (max-width: 640px) {
  .form-field--with-action {
    flex-flow: column nowrap;
    align-items: stretch;
  }
}

.form-field--with-action .action {
  margin-left: 10px;
}

.errorsContainer {
  padding: 8px;
  border: 1px solid rgb(216, 8, 8, 0.4);
  background: rgb(216, 8, 8, 0.1);
  margin-top: 8px;
  border-radius: 4px;
  color: #d80808;
}

.errorItem {
}

.openCallBackModal {
  position: absolute;
  bottom: 64px;
  left: 35px;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  z-index: 10;
  transform: translateY(0px);
  cursor: pointer;
  outline: none;
}

.openCallBackModal svg {
  position: relative;
  z-index: 10;
}
.openCallBackModal:before {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: linear-gradient(180deg, #8a00aa 0%, #5c0670 100%);
  box-shadow: 10px 14px 24px rgba(0, 0, 0, 0.25);
}
.openCallBackModal:after {
  width: 112px;
  height: 112px;
  background: linear-gradient(180deg, #8a00aa 0%, #5c0670 100%);
  opacity: 0.12;
}
.openCallBackModal:after,
.openCallBackModal:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 50%;
  transition: all 0.2s;
  -webkit-animation: 2s ease-in 1s infinite alternate pulse;
  animation: 2s ease-in 1s infinite alternate pulse;
}
@-webkit-keyframes pulse {
  from {
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    transform: translate(-50%, -50%) scale(1.15);
  }
}
@keyframes pulse {
  from {
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    transform: translate(-50%, -50%) scale(1.15);
  }
}

.openCallBackModal:hover::before {
  transform: translate(-50%, -50%) scale(1.05);
  -webkit-animation: none;
  animation: none;
}
.openCallBackModal:hover::after {
  transform: translate(-50%, -50%) scale(1.2);
  -webkit-animation: none;
  animation: none;
}

.openCallBackModal:active::after,
.openCallBackModal:active::before {
  transform: translate(-50%, -50%) scale(0.95);
}

.callbackForm.modal-form {
  bottom: 0;
  left: 0;
  top: auto;
  overflow: visible;
  width: auto;
  height: auto;
  display: block;
  z-index: 2000;
}
.callbackForm.modal-form.active {
}

.callbackForm .modal-container {
  position: fixed;
  bottom: 90px;
  left: 70px;
  opacity: 0;
  transform: translateX(-60px);
  transition: all 0.2s;
  animation: none;
  visibility: hidden;
}

@media (max-width: 640px) {
  .callbackForm .modal-container {
    z-index: 100;
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 24px;
    padding: 56px 32px 64px;
  }
}

.callbackForm.active .modal-container {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

/****/
.giftForm .button-gift.hide {
  visibility: hidden;
  opacity: 0;
}

.giftForm.active .modal-container {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.giftForm.modal-form {
  right: 0;
  left: auto;
  top: auto;
  bottom: 0;
  width: auto;
  height: auto;
  background-color: transparent;
  padding: 0;
  /* padding-left: 0; */
  padding-bottom: 40px;
  overflow: inherit;
  animation: none;
}

.giftForm .modal-container {
  opacity: 0;
  max-width: 500px;
  /* padding-left: 72px; */
  box-shadow: 0 8px 40px rgb(0, 0, 0, 0.16);
  background: linear-gradient(
    122deg,
    #ffd4d7 7.8%,
    #e4d4ff 58.31%,
    #d4ddff 89.29%
  );
  border-color: #e4d4ff;
}
.giftForm .modal-container .modal-close-icon:after,
.giftForm .modal-container .modal-close-icon:before {
  border-left: 2px solid rgba(0, 0, 0, 0.32);
}
.giftForm .modal-container .modal-close-icon:hover::before,
.giftForm .modal-container .modal-close-icon:hover::after {
  border-color: rgba(0, 0, 0, 0.6) !important;
}

.giftForm .modal-container .form-header {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.giftForm .modal-container .form-header > h4 {
  margin-bottom: 0;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.3em;
}
.giftForm .modal-container .form-header > p {
  font-size: 1.2em;
  line-height: 1.5em;
  font-weight: 400;
  margin: 0;
  opacity: 0.9;
}

.giftForm .modal-container > svg {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-45%, -22%);
}

.giftForm .modal-container small {
  color: rgba(0, 0, 0, 0.45);
}

.giftForm.modal-form {
  bottom: 0;
  right: 0;
  left: auto;
  top: auto;
  overflow: visible;
  width: auto;
  height: auto;
  display: block;
  z-index: 1900;
}

.giftForm.modal-form.active {
  z-index: 2100;
}

.giftForm .modal-container {
  position: fixed;
  bottom: 90px;
  right: 70px;
  opacity: 0;
  transform: translateX(-60px);
  transition: all 0.2s;
  animation: none;
  visibility: hidden;
}

@media (max-width: 640px) {
  .giftForm .modal-container {
    z-index: 100;
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 24px;
    padding: 56px 32px 64px;
  }
}

.giftForm .button-gift {
  display: block;
  width: 132px;
  height: 132px;
  background-image: url(/images/gift/gift.png);
  background-size: 132px;
  background-repeat: no-repeat;
  outline: none !important;
  border: 0;
  background-color: transparent;
  transition: all 0.2s;
  animation: 10s infinite b-stay;
  transform: scale(1);
  cursor: pointer;
  /* -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; */
}
.giftForm .button-gift label {
  display: inline-block;
  background-color: var(--color-orangered-100);
  color: var(--color-white);
  border-radius: 20px;
  font-size: 12px;
  padding: 5px 6px;
  box-shadow: 0 2px 4px #00448c3d;
  cursor: pointer;
}
.giftForm .button-gift:hover {
  /* transform: scale(0.8); */
  animation: 8s infinite b-stay, 0.3s 1 forwards b-hover;
}
.giftForm .button-gift:active {
  /* transform: scale(0.9); */
  /* animation-play-state: paused, paused; */
}
.giftForm.active .button-gift {
  animation: 8s infinite b-stay, 0.3s 1 forwards b-open;
  /* animation-play-state: paused, paused; */
}

@-webkit-keyframes b-open {
  100% {
    transform: scale(0.92);
  }
}
@keyframes b-open {
  100% {
    transform: scale(0.92);
  }
}

@-webkit-keyframes b-hover {
  100% {
    transform: scale(1.08);
  }
}
@keyframes b-hover {
  100% {
    transform: scale(1.08);
  }
}

@-webkit-keyframes b-stay {
  20% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.05);
  }
}
@keyframes b-stay {
  20% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.05);
  }
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
